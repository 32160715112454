var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"900"},model:{value:(_vm.dialog.flag),callback:function ($$v) {_vm.$set(_vm.dialog, "flag", $$v)},expression:"dialog.flag"}},[_c('v-card',{staticClass:"pb-0"},[_c('v-card-title',{staticClass:"text-h6 primary_2 white--text",attrs:{"primary-title":""}},[_c('p',[_vm._v("Compare Aadhaar Details")]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center"},[(_vm.dialog.aadhar_pdf_url)?_c('v-btn',{staticClass:"white py-4 mx-3",attrs:{"small":"","text":"","color":""},on:{"click":function($event){_vm.isImageDocument(_vm.dialog.aadhar_pdf_url)
                ? _vm.previewImage(_vm.dialog.aadhar_pdf_url, 'Aadhaar Card')
                : _vm.viewDocument(_vm.dialog.aadhar_pdf_url)}}},[_c('v-icon',{attrs:{"color":"deep-purple darken-4"}},[_vm._v("mdi-eye-outline")])],1):_c('div',{staticClass:"mx-3 font-weight-bold text-caption white--text"},[_vm._v(" No PDF available ")]),_c('v-icon',{attrs:{"large":"","color":"white"},on:{"click":function($event){_vm.dialog.flag = false}}},[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',{staticClass:"pb-0"},[_c('table',{staticClass:"font-weight-bold compare-table"},[_c('tr',{staticClass:"font-weight-bold black--text"},[_c('td',[_vm._v("Status")]),_c('td',{staticClass:"compare-table-paramenter"},[_vm._v("Paramenters")]),_c('td',[_vm._v("Customer filled data")]),_c('td',[_vm._v("Aadhaar Data from Signzy")]),(_vm.dialog.pan_fetch_response)?_c('td',{staticClass:"compare-table-pan-details"},[_vm._v(" PAN Fetch Data from Signzy ")]):_vm._e()]),_c('tr',[_c('td',[(
                  _vm.dialog.user_fiiled_data.name ==
                    _vm.dialog.signzy_fetched_data.name
                )?_c('p',[_c('v-icon',{staticClass:"success--text"},[_vm._v(" mdi-check-circle-outline ")])],1):_c('p',[_c('v-icon',{staticClass:"secondary_2--text"},[_vm._v(" mdi-alert-circle-outline ")])],1)]),_c('td',{staticClass:"black--text"},[_vm._v("Full Name")]),_c('td',[_vm._v(_vm._s(_vm.dialog.user_fiiled_data.name))]),_c('td',[_vm._v(_vm._s(_vm.dialog.signzy_fetched_data.name))]),(_vm.dialog.pan_fetch_response)?_c('td',[_vm._v(" "+_vm._s(_vm.dialog.pan_fetch_response.name)+" ")]):_vm._e()]),_c('tr',[_c('td',[(
                  _vm.dialog.user_fiiled_data.dob ==
                    _vm.dialog.signzy_fetched_data.dob
                )?_c('p',[_c('v-icon',{staticClass:"success--text"},[_vm._v(" mdi-check-circle-outline ")])],1):_c('p',[_c('v-icon',{staticClass:"secondary_2--text"},[_vm._v(" mdi-alert-circle-outline ")])],1)]),_c('td',{staticClass:"black--text"},[_vm._v("Date of Birth")]),_c('td',[_vm._v(_vm._s(_vm.dialog.user_fiiled_data.dob))]),_c('td',[_vm._v(_vm._s(_vm.dialog.signzy_fetched_data.dob))]),(_vm.dialog.pan_fetch_response)?_c('td',[_vm._v(" "+_vm._s(_vm.dialog.pan_fetch_response.dob)+" ")]):_vm._e()]),_c('tr',[_c('td'),_c('td',{staticClass:"black--text"},[_vm._v("Father's Name")]),_c('td',[_vm._v(_vm._s(_vm.dialog.user_fiiled_data.father_name))]),_c('td'),(_vm.dialog.pan_fetch_response)?_c('td',[_vm._v(" "+_vm._s(_vm.dialog.pan_fetch_response.fatherName)+" ")]):_vm._e()]),_c('tr',[_c('td',[(
                  _vm.dialog.user_fiiled_data.address ==
                    _vm.dialog.signzy_fetched_data.address
                )?_c('p',[_c('v-icon',{staticClass:"success--text"},[_vm._v(" mdi-check-circle-outline ")])],1):_c('p',[_c('v-icon',{staticClass:"secondary_2--text"},[_vm._v(" mdi-alert-circle-outline ")])],1)]),_c('td',{staticClass:"black--text"},[_vm._v("Permanent Address")]),_c('td',[_vm._v(_vm._s(_vm.dialog.user_fiiled_data.permanent_address))]),_c('td',[_vm._v(_vm._s(_vm.dialog.signzy_fetched_data.address))]),_c('td')]),_c('tr',[_c('td'),_c('td',{staticClass:"black--text"},[_vm._v("Current Address")]),_c('td',[_vm._v(_vm._s(_vm.dialog.user_fiiled_data.current_address))]),_c('td'),_c('td')])])]),(_vm.dialog.aadhaar_status == 'Under Review')?_c('v-card-actions',{staticClass:"d-flex justify-end pb-6 pr-6"},[_c('v-btn',{staticClass:"font-weight-bold ml-2",attrs:{"outlined":"","small":"","color":"success","disabled":_vm.btn_loader,"loading":_vm.btn_loader},on:{"click":function($event){return _vm.updateKycStatus('aadhar_status', 'Success')}}},[_vm._v(" Apporve ")]),_c('v-btn',{staticClass:"font-weight-bold ml-2",attrs:{"small":"","outlined":"","color":"error","disabled":_vm.btn_loader,"loading":_vm.btn_loader},on:{"click":function($event){return _vm.updateKycStatus('aadhar_status', 'Failed')}}},[_vm._v(" Reject ")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }